<template>
  <div class="bodycont" id='bodycont'>

    <comHeader />
<div class="infobanner"></div>
    <div class="main" >
      <navTitle :navData='navData'/>
      <div >
        <h4 class='title'>{{dataInfo.title}}</h4>
        <p class="hur1">发布时间：{{dataInfo.releasetime}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;文号:{{dataInfo.documentNo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;发布单位：{{dataInfo.unit}}</p>
        <div id="zoom" class="cont" v-html="dataInfo.detail">
        </div>
      </div>
    </div>
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      navTitle
    },
    watch: {
      '$route': function(to, from) {
  　    document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
    },
    data() {
      return {
        navData:[ ],
        newId:'',
        dataInfo:{},
      }
    },

    created() {
      this.newId = this.$route.query.id;
      this. navData=[
          {
            name:this.$route.query.name,
            url:this.$route.query.url,
          },
          {
            name:'详情页',
            url:'newsdetails',
          },
      ]
    },
    mounted() {
      this.getInfo()
    },
    methods: {
      //获取列表
      getInfo() {
        let data = {
          newId: this.newId,
        }
        postRequest(api.newsDetails, data).then(res => {
           this.dataInfo = res.data
        })

      },
      //查看详情
      tourl(data) {
        this.$router.push({
          path: '/teamDetails',
          query: {},
        });
      }

    }
  }
</script>

<style lang="less" scoped>
  .cont{
    /deep/ p{
      margin-top: 20px;

    }
    /deep/ span{
     line-height: 30px;
     white-space:normal;
     width: 1180px;
    }
  }
  .title {
      font-size: 30px;
      font-weight: normal;
      color: #547d98;
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: center;}
      .hur1 {
          text-align: center;
          line-height: 30px;
          margin-bottom: 20px;
      }
  .bodycont {
    // background: url('../../assets/images/homebg1.png') repeat-x;
    width: 100%;
  }
  .main{
    width:1190px;
    margin:0px auto 20px;
    min-height: 75vh;
  }
 .infobanner{
   background: url('../../assets/images/banner/about.png') center center no-repeat;
   width: 100%;
   height:350px;
   background-size:cover;
 }


</style>
